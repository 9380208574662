$path-to-els-core-logos: '~@els/els-component-footer-react/module/assets/images/';
$path-to-els-core-fonts: '~@els/els-styleguide-core/fonts';
$path-to-els-common-images: '~@els/els-ui-common-react/module/assets/images/';

@import '~@els/els-styleguide-core/scss/core';
@import '~@els/els-styleguide-button/scss/component.button';
@import '~@els/els-styleguide-link/scss/component.link';
@import '~@els/els-styleguide-form-field/scss/component.form-field';
@import '~@els/els-styleguide-table/scss/component.table';
@import '~@els/els-styleguide-menu/scss/component.menu';
@import '~@els/els-styleguide-modal/scss/component.modal';
@import '~@els/els-styleguide-toast/scss/component.toast';

@import '~@els/els-component-form-field-react/module/assets/scss/main';
@import '~@els/els-component-button-react/module/assets/scss/main';
@import '~@els/els-component-data-table-react/module/assets/scss/main';
@import '~@els/els-component-menu-react/module/assets/scss/main';
@import '~@els/els-component-modal-react/module/assets/scss/main';
@import '~@els/els-component-toast-react/module/assets/scss/main';

@import '~@els/els-ui-common-react/module/assets/scss/main';

//Application styles

.c-al-tool-background {
  background-image: url("iStock_000054452778_Full.jpg");
  width: 100%;
  height: 100%;
  background-size: cover;
  position: absolute;

  &--no-background-image {
    background-image: none;
  }

  .c-els-modal__window {
    width: 30 * $els-space;
  }
}

@media (prefers-color-scheme: dark) {

  body {
    color: $els-color-n0;
    background: $els-color-n10;
  }
  .c-al-tool-background {
    .c-els-modal &__window {
      background: $els-color-n10;
    }
  }
  .c-els-modal {
    background: rgba(0, 0, 0, 0.85);

    &__window {
      background: $els-color-n10;
    }
  }
  .u-els-anchorize {
    color: $els-color-blue-light
  }

  .c-els-field__wrap,
  .c-els-field__input,
  textarea {
    background: $els-color-n8;
    color: $els-color-n0;
  }

  .c-els-button--tertiary,
  .c-els-button--secondary {
    background-color: $els-color-n3;
  }

  .c-els-toast__item {
    background: $els-color-n8;
    color: $els-color-n0;
  }

  .c-els-page-loader {
    background-color: rgb(20, 20, 20);
    background-color: rgba(20, 20, 20, 0.7);
  }

  .c-els-tooltip {
    z-index: 100;
    position: fixed;
    background-color: $els-color-n6;
    padding: $els-space;
    border: $els-thin-keyline-width;
    border-radius: $els-space-1o2;
    color: $els-color-n0;
    width: auto;
    height: auto;
    margin-top: $els-space-4x;
    margin-left: -7rem;
  }

  .c-els-tooltip-wrapper {
    background-color: transparent;
    border: none;
  }

  .c-els-button--background-white {
    background-color: $els-color-n0;
  }

  .c-els-list {
    padding-left: $els-space-1x1o2;

    &__item {
      list-style-type: disc;
    }
  }
}
